
import { defineComponent, onMounted } from "vue";
import PolicyTableDropdownOptions from "@/components/individual/PolicyTableDropdownOptions.vue";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "policies-table",
  components: { PolicyTableDropdownOptions },
  props: {
    widgetClasses: String,
    policySummary: Object,
    policies: Array,
  },
  setup(props) {
    const { isEmptyArray, displayDatatable } = ResusableFunctions();

    const formatDate = (dateString) => {
      return variables.formatDate(dateString);
    };

    const addCommaToNumberString = (amount) => {
      return variables.addCommaToNumberString(amount);
    };

    onMounted(() => {
      if (!isEmptyArray(props.policies)) {
        displayDatatable(["basicExample"]);
      }
    });

    return {
      formatDate,
      addCommaToNumberString,
    };
  },
});
