
import { defineComponent, onMounted } from "vue";
import PolicyTable from "@/components/individual/PolicyTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";

export default defineComponent({
  name: "individual-client-policy",
  components: {
    PolicyTable
  },
  data() {
    return {
      claimsSummary: null,
      policySummary: {},
      policies: []
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("My Policies");
      MenuComponent.reinitialization();
    });
  },
  created() {
    //Set page title
    document.title = "My Policies | " + process.env.VUE_APP_TITLE;

    this.getPolicies();
  },
  methods: {
    getPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.INDIVIDUAL_CLIENT_DASHBOARD)
          .then(({ data }) => {
            //Assign data to props
            this.claimsSummary = data.data.claimsSummary;
            this.policySummary = data.data.policySummary;
            this.policies = data.data.policies;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    }
  }
});
